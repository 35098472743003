












































































import {Component, Vue} from 'vue-property-decorator';
import BhInput from '@/components/BhInput.vue';
import Vuelidate from 'vuelidate';
import {email, required, sameAs, minLength, helpers} from 'vuelidate/lib/validators';
import {vxm} from '@/store';

Vue.use(Vuelidate);

@Component({
  components: {BhInput},
  validations: {
    form1: {
      email: {
        required,
        email,
      },
    },
    form2: {
      password: {
        required,
        minLength: minLength(8),
        alpha: helpers.regex('alpha', /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=]).{8,64}$/),
      },
      password2: {
        required,
        sameAs: sameAs('password'),
      },
    },
  },
})
export default class RestorePassword extends Vue {
  form1 = {
    email: '',
  };
  form2 = {
    password: '',
    password2: '',
  };
  token = '';
  showPass = false;
  showPass2 = false;
  createPass = false;
  showSuccess = false;
  recoverPass = false;
  restoreError = false;
  mounted() {
    this.createPass = !!this.$route.params.token;
  }
  get passwordErrorMessage(): string {
    if (this.form2.password.includes(' ')) {
      return 'Password must not contain spaces';
    }
    if (!this.$v.form2.password?.required) {
      return 'Password is required field';
    }
    if (!this.$v.form2.password?.minLength) {
      return `Password should be at least ${this.$v.form2.password?.$params.minLength.min} symbols long.`;
    }
    if (!this.$v.form2.password?.alpha) {
      return 'At least one capital letter, one digit and one special symbol.';
    }
    return '';
  }
  restorePassword() {
    this.$v.form1.$touch();
    if (this.$v.form1.$invalid) {
      return;
    }
    vxm.user
      .requestChangePassword(this.form1.email)
      .then(() => {
        this.showSuccess = true;
      })
      .catch(() => {
        this.restoreError = true;
      });
  }
  recoverPassword() {
    this.$v.form2.$touch();
    if (this.$route.params.token) {
      this.token = this.$route.params.token;
    }
    if (this.$v.form2.$invalid) {
      return;
    }
    vxm.user.changePassword({data: this.form2, token: this.token}).then(() => {
      this.recoverPass = true;
    });
  }
}
